<template>
    <v-dialog v-model="show" max-width="800px" persistent>
        <v-card>
            <v-card-title class="headline error white--text d-flex justify-space-between">
                Retoure anmelden
                <v-btn icon @click="$emit('update:show', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-select
                                outlined
                                dense
                                label="Retoure Typ"
                                v-model="retoureType"
                                :items="retoureTypes"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                outlined
                                dense
                                label="Carrier"
                                v-model="carrier"
                                :items="carriers"
                                required
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                outlined
                                dense
                                label="Order ID"
                                v-model="formData.orderId"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                outlined
                                dense
                                label="Stock Keeping Unit"
                                v-model="formData.sku"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                outlined
                                dense
                                label="Vorname"
                                v-model="formData.customer.firstName"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                outlined
                                dense
                                label="Nachname"
                                v-model="formData.customer.lastName"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                outlined
                                dense
                                label="Anschrift"
                                v-model="formData.customer.adress"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                v-model="reason"
                                :items="retoureType === 'NotDelivered' ? notDeliveredReasons : retoureReason"
                                item-text="label"
                                item-value="value"
                                label="Grund"
                                required
                                @change="handleReasonChange"
                                return-object
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-text>
                <v-alert type="error">
                    <span>
                        Du versendest eine Nachricht an den Kunden, dass sein Paket zurückgesendet wurde.
                    </span>
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey" @click="$emit('update:show', false)">
                    Abbrechen
                </v-btn>
                <v-btn 
                    color="primary" 
                    @click="handleSubmit"
                    :disabled="!isFormValid"
                >
                    <v-icon left>mdi-check</v-icon>
                    Retoure anmelden
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'RetoureCreateModal',
    props: {
        show: Boolean,
        formData: {
            type: Object,
            required: true
        },
        shop: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            retoureType: null,
            carrier: null,
            reason: null,
            billbeeEventName: null,
            retoureTypes: ['Retoure', 'NotDelivered'],
            carriers: ['DHL', 'DPD'],
            notDeliveredReasons: [
                { value: "Retoure_1", label: "nicht abgeholt" },
                { value: "Retoure_3", label: "Empfänger/Firma unter der angegebenen Anschrift nicht zu ermitteln" },
                { value: "Retoure_2", label: "Lieferadresse falsch oder unvollständig" }
            ],
            retoureReason: [
                { value: "delayed_delivery", label: "Kam nicht rechtzeitig an" },
                { value: "wrong_item", label: "Falscher Artikel versendet" },
                { value: "damaged_package", label: "Versandverpackung und Artikel beschädigt" },
                { value: "refused_delivery", label: "Empfänger hat Annahme verweigert: nicht gewünscht" },
                { value: "poor_quality", label: "Leistung oder Qualität ungenügend" },
                { value: "no_reason", label: "kein Grund angegeben" },
                { value: "shipping_conditions", label: "Sendung entspricht nicht den Versandbedingungen" },
                { value: "ordered_by_mistake", label: "Irrtümlich bestellt" }
            ]
        }
    },
    computed: {
        isFormValid() {
            return this.retoureType && 
                   this.carrier && 
                   this.reason &&
                   this.formData.orderId &&
                   this.formData.sku;
        }
    },
    methods: {
        handleSubmit() {
            if (!this.isFormValid) return;
            
            this.$emit('submit', {
                type: this.retoureType,
                carrier: this.carrier,
                orderId: this.formData.orderId,
                managedId: this.formData.managedId,
                shop: this.shop,
                sku: this.formData.sku,
                reason: this.reason,
                customer: this.formData.customer,
                billbeeEventName: this.billbeeEventName
            });
        },
        handleReasonChange(selectedItem) {
            if (!selectedItem) return;

            this.reason = selectedItem.label;
            this.billbeeEventName = selectedItem.value;
        }
    },
    watch: {
        show(newVal) {
            if (!newVal) {
                // Reset form when dialog closes
                this.retoureType = null;
                this.carrier = null;
                this.reason = null;
            }
        },
        retoureType() {
            // Reset reason when type changes
            this.reason = null;
        }
    }
}
</script>

<style scoped>
.v-card-title {
    border-bottom: 1px solid #e0e0e0;
}
</style> 