<script>
export default {
    name: 'RetourePrinterWarning',
    props: {
        show: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <v-dialog v-model="show" max-width="500" persistent>
        <v-card>
            <v-card-title class="text-h5 white--text error">
                <v-icon left size="24" color="white">mdi-alert-circle</v-icon>
                <h3>Kein Drucker ausgewählt!</h3>
            </v-card-title>
            
            <v-card-text class="pa-6">
                <div class="warning-content">
                    <v-icon color="amber darken-2" size="64" class="mb-4">mdi-printer-off</v-icon>
                    <p class="text-body-1">
                        Bitte wählen Sie einen Drucker in den Einstellungen aus, bevor Sie die Retoure erstellen.
                    </p>
                    <p class="text-caption mt-2">
                        Ohne einen ausgewählten Drucker können keine Etiketten gedruckt werden.
                    </p>
                </div>
            </v-card-text>
            
            <v-card-actions class="pb-4 px-4">
                <v-spacer></v-spacer>
                <v-btn 
                    color="primary" 
                    depressed 
                    @click="$emit('close')"
                    class="px-5"
                >
                    Verstanden
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.warning-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px 0;
}
</style>