<script>
import ProductionService from '@/services/ProductionService';

export default {
    name: 'RetoureShelf',
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        compartment: {
            type: Number,
            required: true
        },
        headers: {
            type: Array,
            required: true
        },
        compartmenItems: {
            type: Array,
            required: true
        },
        toSendItems: {
            type: Array,
            required: true
        },
        trashItems: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            tab: 0,
            printer: null,
            errorMessage: null,
            snackbar: false,
            multiLine: true
        }
    },
    computed: {
        sortedToSendItems() {
            return this.toSendItems
                .filter(item => item.shelf === this.compartment)
                .sort((a, b) => a.orderId - b.orderId);
        },
        sortedTrashItems() {
            return this.trashItems
                .filter(item => item.shelf === this.compartment)
                .sort((a, b) => a.orderId - b.orderId);
        }
    },
    methods: {
        async sendRetoureItem(orderId) {
            try {
                let printer = this.$cookies.get('printer');

                if (!printer) {
                    this.errorMessage = 'Kein Drucker ausgewählt!';
                    this.snackbar = true;
                    return;
                } 
                
                let response = await ProductionService.reShipRetoure(orderId, {
                    printer: printer.url
                });

                if (response.status === 200) {
                    this.$emit('closeDialog');
                    this.$emit('refreshRetoureShelf');
                }
            } catch (error) {
                console.log(error);
            }
        },
        async putRetoureItemInTrash(item) {
            try {
                item.status = 'inTrash';
            
                let orderObj = {
                    retoure: item,
                    billbee: null
                }
                await ProductionService.updateRetoure(orderObj, false);
            } catch (error) {
                console.error('Failed to put retoure into trash:', error);
            }
        },
        closeShelfDialog() {
            this.dialog = false;
            this.$emit('closeDialog');
        }
    }
}

</script>

<template>
    <div>
        <v-dialog v-model="dialog" max-width="800px">
        <v-card>
            <v-card-title class="d-flex align-center">
                <v-icon left>mdi-package-variant</v-icon>
                Fach {{ compartment }}
                <v-spacer></v-spacer>
                <v-btn icon @click="closeShelfDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-4">
                <v-tabs v-model="tab">
                    <v-tab>Zu Versenden ({{ sortedToSendItems.length }})</v-tab>
                    <v-tab>Zu Entsorgen ({{ sortedTrashItems.length }})</v-tab>
                </v-tabs>
                
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-data-table
                            :headers="headers"
                            :items="sortedToSendItems"
                            :items-per-page="5"
                            dense
                            hide-default-footer
                            class="compact-table mt-4"
                        >
                            <template v-slot:item.action="{ item }">
                                <div class="d-flex justify-end">
                                    <v-btn
                                        color="success"
                                        small
                                        class="mr-2"
                                        @click="sendRetoureItem(item.orderId)"
                                    >
                                        <v-icon small>mdi-send</v-icon>
                                        <span class="d-none d-sm-inline">Versenden</span>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                    
                    <v-tab-item>
                        <v-data-table
                            :headers="headers"
                            :items="sortedTrashItems"
                            :items-per-page="5"
                            dense
                            hide-default-footer
                            class="compact-table mt-4"
                        >
                            <template v-slot:item.action="{ item }">
                                <div class="d-flex justify-end">
                                    <v-btn 
                                        @click="putRetoureItemInTrash(item)"
                                        icon
                                        small
                                        color="error"
                                    >
                                        <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      color="error"
      timeout="6000"
    >
      <div class="d-flex align-center">
        <v-icon left color="white">mdi-alert-circle</v-icon>
        <span>{{ errorMessage }}</span>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          color="white"
          v-bind="attrs"
          @click="snackbar = false"
        >
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
    </div>
</template>

<style scoped>
.compact-table {
    --v-data-table-header-mobile-height: 40px;
}

.compact-table >>> th {
    font-size: 0.875rem;
    height: 40px;
}

.compact-table >>> td {
    font-size: 0.875rem;
    height: 40px;
}

.compact-table >>> .v-btn {
    min-width: 36px;
    padding: 0 8px;
}
</style>