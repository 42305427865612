<script>
import RetoureSearchModal from './modals/RetoureSearchModal.vue'
import RetoureCreateModal from './modals/RetoureCreateModal.vue'
import RetoureOrderDetailsModal from './modals/RetoureOrderDetailsModal.vue'
import RetoureEditAddressModal from './modals/RetoureEditAddressModal.vue'
import RetoureSettingsModal from './modals/RetoureSettingsModal.vue'
import RetourePrinterWarning from './modals/RetourePrinterWarning.vue'
import RetoureOrderNotFoundModal from './modals/RetoureOrderNotFoundModal.vue'

export default {
    name: 'RetoureModals',
    components: {
        RetoureSearchModal,
        RetoureCreateModal,
        RetoureOrderDetailsModal,
        RetoureEditAddressModal,
        RetoureSettingsModal,
        RetourePrinterWarning,
        RetoureOrderNotFoundModal
    },
    props: {
        dialogs: {
            type: Object,
            required: true
        },
        orderData: {
            type: Object,
            default: null
        },
        selectedOrderId: {
            type: String,
            default: null
        },
        selectedRetoureItem: {
            type: Object,
            default: null
        },
        isLoadingOrderData: {
            type: Boolean,
            default: false
        },
        searchResults: {
            type: Array,
            default: () => []
        },
        isSearching: {
            type: Boolean,
            default: false
        },
        multiCartItems: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            retoureType: null,
            carrier: null,
            orderId: null,
            managedId: null,
            sku: null,
            reason: null,
            customer: {
                firstName: null,
                lastName: null,
                adress: null
            },
            currentEditingAddress: null,
            currentEditingRetoureItem: null,
            retoureFormData: null,
            isFromSearch: false,
            selectedSearchItem: null
        }
    },
    computed: {
        isFormValid() {
            return this.retoureType && 
                   this.carrier && 
                   this.orderId && 
                   this.sku && 
                   this.reason;
        }
    },
    methods: {
        closeDialog(dialogName) {
            this.$emit('update:dialogs', {
                ...this.dialogs,
                [dialogName]: false
            });

            if (dialogName === 'orderDialog') {
                this.isFromSearch = false;
                this.currentEditingRetoureItem = null;
                this.currentEditingAddress = null;
            }
        },
        viewOrderDetails(data) {
            if (data && typeof data === 'object' && 'isFromSearch' in data) {
                this.isFromSearch = data.isFromSearch;
            } else {
                this.isFromSearch = false;
            }

            this.$emit('update:dialogs', {
                ...this.dialogs,
                searchDialog: false,
                orderDialog: true
            });
            
            this.$emit('view-order-details', data.item || data);
            this.selectedSearchItem = data.item || data;
        },
        submitRetoure(item) {
            let printer = this.$cookies.get('printer');

            this.retoureFormData = {
                orderId: item.orderId,
                sku: item.products[0].sku,
                customer: item.customer,
                managedId: item.managedId
            };

            this.$emit('update:dialogs', {
                ...this.dialogs,
                searchDialog: false,
                retoureDialog: true,
                printerWarning: printer ? false : true
            });
        },
        handleNewRetoure(formData) {
            let printer = this.$cookies.get('printer');
            this.$emit('new-retoure', formData);
            
            this.$emit('update:dialogs', {
                ...this.dialogs,
                retoureDialog: false,
                searchDialog: false,
                orderDialog: false,
                printerWarning: printer ? false : true
            });
        },
        confirmCustomersAddress(orderData) {
            this.$emit('confirm-address', orderData);
        },
        putRetoureIntoTrash(item) {
            this.$emit('put-into-trash', item);
        },
        reloadOrderData() {
            this.$emit('reload-order-data', this.selectedOrderId);
        },
        initializeRetoureForm(data) {
            this.orderId = data.orderId;
            this.managedId = data.billbeeOrder.BillBeeOrderId;
            this.sku = data.sku;
            this.customer = {
                firstName: data.customer.firstName,
                lastName: data.customer.lastName,
                adress: data.customer.adress
            };
            // Reset selectable fields
            this.retoureType = null;
            this.carrier = null;
            this.reason = null;
        },
        editCustomersAddress(data) {
            this.$emit('update:dialogs', {
                ...this.dialogs,
                editAdressDialog: true
            });

            this.currentEditingRetoureItem = data.retoure;
            this.currentEditingAddress = data.billbee;
        },
        saveAddress(data) {
            this.$emit('save-address', data);
        }
    },
    watch: {
        'dialogs.retoureDialog'(newVal) {
            if (!newVal) {
                this.retoureType = null;
                this.carrier = null;
                this.reason = null;
            }
        },
        'dialogs.orderDialog': {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    this.isFromSearch = false;
                }
            }
        }
    }
}
</script>

<template>
    <div class="retoure-modals">
        <RetoureSearchModal
            :show.sync="dialogs.searchDialog"
            :is-searching="isSearching"
            :search-results="searchResults"
            @view-order="viewOrderDetails"
            @submit-retoure="submitRetoure"
        />

        <RetoureSettingsModal
            :show.sync="dialogs.settings"
            @close="closeDialog('settings')"
        />

        <RetoureOrderDetailsModal
            :show.sync="dialogs.orderDialog"
            :order-data="orderData"
            :selected-order-id="selectedOrderId"
            :selected-retoure-item="selectedRetoureItem"
            :selected-search-item="selectedSearchItem"
            :is-loading-order-data="isLoadingOrderData"
            :is-from-search="isFromSearch"
            @update-show="closeDialog('orderDialog')"
            @confirm-address="confirmCustomersAddress"
            @put-into-trash="putRetoureIntoTrash"
            @reload-order-data="$emit('reload-order-data')"
            @submit-retoure="submitRetoure"
            @edit-adress="editCustomersAddress"
        />

        <RetoureCreateModal
            v-if="orderData"
            :show.sync="dialogs.retoureDialog"
            :form-data="retoureFormData"
            :shop="orderData.shop"
            @submit="handleNewRetoure"
        />

        <RetoureEditAddressModal
            v-if="currentEditingAddress"
            :show.sync="dialogs.editAdressDialog"
            :current-editing-address="currentEditingAddress"
            :current-editing-retoure-item="currentEditingRetoureItem"
            @update-show="closeDialog('editAdressDialog')"
            @save-address="saveAddress"
        />

        <RetourePrinterWarning
            :show.sync="dialogs.printerWarning"
            @close="closeDialog('printerWarning')"
        />

        <RetoureOrderNotFoundModal
            :show.sync="dialogs.orderNotFound"
            @close="closeDialog('orderNotFound')"
        />
    </div>
</template>

<style scoped>
.empty-state {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-state h3 {
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.empty-state p {
    max-width: 400px;
    margin: 0 auto;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
}

.v-card-title {
    border-bottom: 1px solid #e0e0e0;
}
</style>