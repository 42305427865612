<template>
    <div>        
        <div class="shelf-container">
            <div 
                v-for="compartment in compartmentsCount" 
                :key="compartment"
                class="shelf-compartment"
                :class="{'has-items': getItemCountForCompartment(compartment) > 0}"
                @click="openDialog(compartment)"
            >
                <div class="compartment-number">Fach {{ compartment }}</div>
                <div class="badge-container">
                    <div class="compartment-trash-count" v-if="getTrashItemCountForCompartment(compartment) > 0">
                        {{ getTrashItemCountForCompartment(compartment) }}
                    </div>
                    <div class="compartment-items-count" v-if="getItemCountForCompartment(compartment) > 0">
                        {{ getItemCountForCompartment(compartment) }}
                    </div>
                </div>
            </div>
        </div>

        <RetoureShelf
            :dialog="dialog"
            :compartment="compartment"
            :headers="headers"
            :compartmenItems="compartmenItems"
            :toSendItems="toSendRetoureItems"
            :trashItems="trashRetoureItems"
            @putRetoureItemInTrash="putRetoureItemInTrash"
            @closeDialog="closeDialog"
            @refreshRetoureShelf="refreshRetoureShelf"
        />
    </div>
</template>

<script>
import ProductionService from "@/services/ProductionService";
import RetoureShelf from "./modals/RetoureShelf.vue";

export default {
    name: 'RetoureShelfManager',
    data() {
        return {
            dialog: false,
            compartment: null,
            compartmenItems: [],
            compartmentsCount: 31,
            headers: [
                { text: 'Bestellnummer', value: 'orderId' },
                { text: 'Stock Keeping Unit', value: 'sku' },
                { text: 'Name', value: 'name' },
                { value: 'action', align: 'end' },
            ]
        }
    },
    components: {
        RetoureShelf
    },
    props: {
        toSendRetoureItems: {
            type: Array,
            required: true
        },
        trashRetoureItems: {
            type: Array,
            required: true
        }
    },
    methods: {
        getItemCountForCompartment(compartment) {
            return this.toSendRetoureItems.filter(item => item.shelf === compartment).length;
        },
        getTrashItemCountForCompartment(compartment) {
            return this.trashRetoureItems.filter(item => item.shelf === compartment).length;
        },
        openDialog(compartment) {
            this.compartment = compartment;
            this.compartmenItems = this.toSendRetoureItems.filter(item => item.shelf === compartment);

            this.toSendItems = this.toSendRetoureItems.filter(item => item.shelf === compartment);
            this.trashItems = this.trashRetoureItems.filter(item => item.shelf === compartment);
            
            if (this.compartmenItems.length > 0 || this.trashItems.length > 0) {
                this.dialog = true;
            }
        },
        closeDialog() {
            this.dialog = false;
        },
        async sendRetoureItem(item) {
            try {
                console.log(item);

                let response = await ProductionService.createShipment(item);
                
                if (response.status === 200) {
                    console.log("Shipment created");
                }
            } catch (error) {
                console.error(error);
            }
        },
        putRetoureItemInTrash(item) {
            this.$emit('putRetoureItemInTrash', item);
        },
        refreshRetoureShelf() {
            this.$emit('refreshRetoureShelf');
        }
    }
}
</script>

<style scoped>
.shelf-container {
    display: grid;
    gap: 12px;
    margin: 20px;
    padding: 0 16px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

.shelf-compartment {
    position: relative;
    aspect-ratio: 1 / 1;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.shelf-compartment:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.shelf-compartment.has-items {
    background-color: #e3f2fd;
    border-color: #2196f3;
    box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
}

.compartment-number {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 4px;
}

.badge-container {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 4px;
}

.compartment-items-count {
    background-color: #2196f3;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.compartment-trash-count {
    background-color: #f44336;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* For browsers that don't support aspect-ratio */
@supports not (aspect-ratio: 1 / 1) {
    .shelf-compartment {
        height: 0;
        padding-top: 100%;
    }
    
    .shelf-compartment > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* Adjustments for smaller screens */
@media (max-width: 600px) {
    .shelf-container {
        gap: 8px;
        margin: 12px;
        padding: 0 8px;
    }
    
    .compartment-number {
        font-size: 1rem;
    }
    
    .compartment-items-count {
        width: 20px;
        height: 20px;
        font-size: 0.8rem;
    }
}
</style>
