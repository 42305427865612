<script>
export default {
    name: 'RetoureOrderNotFoundModal',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        searchTerm: {
            type: String,
            default: ''
        }
    }
}
</script>

<template>
    <v-dialog v-model="show" max-width="500" persistent>
        <v-card>
            <v-card-title class="headline grey lighten-3">
                <v-icon left color="grey darken-2">mdi-file-search-outline</v-icon>
                Bestellung nicht gefunden
            </v-card-title>
            
            <v-card-text class="pa-6">
                <div class="not-found-content">
                    <v-icon color="grey lighten-1" size="80" class="mb-4">mdi-package-variant-closed-remove</v-icon>
                    
                    <h3 class="text-h6 mb-2">Keine passende Bestellung gefunden</h3>
                    
                    <p class="text-body-1 text-center">
                        Die Bestellung <strong>{{ searchTerm }}</strong> konnte nicht in unserer Datenbank gefunden werden.
                    </p>
                    
                    <v-alert
                        dense
                        outlined
                        type="info"
                        class="mt-4 mb-3"
                    >
                        Überprüfen Sie die Bestellnummer und versuchen Sie es erneut.
                    </v-alert>
                </div>
            </v-card-text>
            
            <v-divider></v-divider>
            
            <v-card-actions class="pa-4">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="grey darken-1"
                    @click="$emit('close')"
                >
                    Schließen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px 0;
}
</style>