<script>
import RetoureEditAddressModal from './RetoureEditAddressModal.vue'

export default {
    name: 'RetoureOrderDetailsModal',
    components: {
        RetoureEditAddressModal
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        isFromSearch: {
            type: Boolean,
            default: false
        },
        orderData: {
            type: Object,
            default: null
        },
        selectedOrderId: {
            type: String,
            default: null
        },
        selectedSearchItem: {
            type: Object,
            default: null
        },
        selectedRetoureItem: {
            type: Object,
            default: null
        },
        isLoadingOrderData: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            source: null
        }
    },
    computed: {
        isAnonymous() {
            for (const [key, value] of Object.entries(this.orderData.ShippingAddress)) {
                const keyStr = key.toString().toLowerCase();
                const valueStr = value ? value.toString().toLowerCase() : '';
                if (keyStr.includes('anonym') || valueStr.includes('anonym')) {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        closeDialog() {
            this.$emit('update:show', false);
        },
        confirmCustomersAddress() {
            this.$emit('confirm-address', this.selectedRetoureItem);
        },
        editCustomersAddress() {
            this.$emit('edit-adress', {
                retoure: this.selectedRetoureItem,
                billbee: this.orderData.ShippingAddress
            });
        },
        putRetoureIntoTrash() {
            this.$emit('put-into-trash', this.selectedRetoureItem);
        },
        reloadOrderData() {
            this.$emit('reload-order-data');
        },
        handleSaveAddress(newAddress) {
            this.$emit('save-address', {
                orderId: this.selectedOrderId,
                address: newAddress
            });
            this.showEditAddressDialog = false;
        },
    },
    watch: {
        show(newVal) {
            if (!newVal) {
                this.source = null;
            }
        },
        orderData: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.source = this.$attrs.source || null;
                }
            }
        },
        isFromSearch: {
            handler(newVal) {
                this.isFromSearch = newVal;
            }
        }
    }
}
</script>

<template>
    <v-dialog v-model="show" max-width="1000px" persistent>
        <v-card class="modal-card">
            <v-card-title class="headline d-flex justify-space-between modal-header">
                <span>Bestelldetails #{{ selectedOrderId }}</span>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            
            <div class="content-container">
                <!-- Empty state -->
                <div v-if="!orderData" class="empty-state text-center py-6">
                    <v-icon size="64" color="grey lighten-1">mdi-clipboard-text-off</v-icon>
                    <h3 class="mt-4 grey--text text--darken-1">Keine Bestelldaten verfügbar</h3>
                    <p class="grey--text">Die Bestelldaten konnten nicht geladen werden oder stehen nicht zur Verfügung.</p>
                    <v-btn 
                        color="primary" 
                        class="mt-4" 
                        small
                        @click="reloadOrderData"
                        :loading="isLoadingOrderData"
                    >
                        <v-icon left small>mdi-refresh</v-icon>
                        Erneut versuchen
                    </v-btn>
                </div>

                <div v-else class="pa-4">
                    <!-- Customer Information Section -->
                    <v-card outlined class="mb-4">
                        <v-card-title class="subtitle-2 grey lighten-4 py-2">
                            <v-icon left small color="primary">mdi-account</v-icon>
                            Kundeninformationen
                        </v-card-title>
                        <v-card-text class="pt-3 pb-2">
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <div class="info-group">
                                        <div class="info-label">Name</div>
                                        <div class="info-value">{{ orderData?.ShippingAddress?.FirstName }} {{ orderData?.ShippingAddress?.LastName }}</div>
                                    </div>
                                    <div class="info-group mt-3">
                                        <div class="info-label">Adresse</div>
                                        <div class="info-value">
                                            {{ orderData?.ShippingAddress?.Street }} {{ orderData?.ShippingAddress?.HouseNumber }}
                                            <br>
                                            {{ orderData?.ShippingAddress?.Zip }} {{ orderData?.ShippingAddress?.City }}
                                            <br>
                                            {{ orderData?.ShippingAddress?.CountryISO2 }}
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="info-group">
                                        <div class="info-label">E-Mail</div>
                                        <div class="info-value">{{ orderData?.ShippingAddress?.Email }}</div>
                                    </div>
                                    <div class="info-group mt-3">
                                        <div class="info-label">Telefon</div>
                                        <div class="info-value">{{ orderData?.ShippingAddress?.Phone || '-' }}</div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider class="my-3"></v-divider>
                            <div class="d-flex justify-space-between py-1">
                                <v-btn
                                    v-if="!isFromSearch && !isAnonymous"
                                    color="success"
                                    small
                                    @click="confirmCustomersAddress"
                                >
                                    <v-icon left small>mdi-check</v-icon>
                                    Adresse bestätigen
                                </v-btn>
                                <v-btn
                                    v-if="!isFromSearch && !isAnonymous"
                                    color="primary"
                                    small
                                    outlined
                                    @click="editCustomersAddress"
                                >
                                    <v-icon left small>mdi-pencil</v-icon>
                                    Adresse bearbeiten
                                </v-btn>
                                <v-btn
                                    v-if="!isFromSearch && isAnonymous"
                                    color="primary"
                                    small
                                    outlined
                                    @click="editCustomersAddress"
                                >
                                    <v-icon left small>mdi-ticket</v-icon>
                                    Asana Ticket erstellen
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>

                    <!-- Order Items Section -->
                    <v-card outlined>
                        <v-card-title class="subtitle-2 grey lighten-4 py-2">
                            <v-icon left small color="primary">mdi-package-variant</v-icon>
                            Bestellte Artikel
                        </v-card-title>
                        <v-card-text class="pt-3">
                            <div v-if="orderData?.OrderItems && orderData.OrderItems.length > 0">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">Artikel</th>
                                                <th class="text-right" width="100">Menge</th>
                                                <th class="text-right" width="120">Preis</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in orderData.OrderItems" :key="index">
                                                <td>
                                                    <div class="d-flex align-center">
                                                        <v-chip x-small color="grey" text-color="white" class="mr-2">
                                                            {{ item.Product.SKU }}
                                                        </v-chip>
                                                        <span class="text-body-2">{{ item.Product.Title }}</span>
                                                    </div>
                                                </td>
                                                <td class="text-right">{{ item.Quantity }}x</td>
                                                <td class="text-right">{{ item.TotalPrice }} {{ orderData.Currency }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                            <div v-else class="text-center my-4">
                                <v-icon color="grey lighten-1">mdi-cart-off</v-icon>
                                <div class="caption grey--text mt-2">Keine Artikeldaten verfügbar</div>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
            
            <v-card-actions class="modal-footer">
                <v-spacer></v-spacer>
                <v-btn color="grey" text small @click="closeDialog">Schließen</v-btn>
                <v-btn
                    v-if="isFromSearch"
                    small 
                    color="success"
                    @click="$emit('submit-retoure', selectedSearchItem)"
                    :disabled="!orderData"
                    >
                    <v-icon left small>mdi-refresh</v-icon>
                    Retoure erstellen
                </v-btn>
                <v-btn
                    v-if="isFromSearch"
                    small 
                    color="warning"
                    disabled
                    >
                    <v-icon left small>mdi-refresh</v-icon>
                    Reklamation erstellen
                </v-btn>
                <v-btn 
                    v-else
                    small
                    color="error"
                    :disabled="!orderData"
                    @click="putRetoureIntoTrash"
                >
                    <v-icon left small>mdi-trash-can</v-icon>
                    Retoure entsorgen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.modal-card {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
}

.modal-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.content-container {
    flex: 1;
    overflow-y: auto;
}

.empty-state {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.info-group {
    margin-bottom: 8px;
}

.info-label {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
}

.info-value {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.4;
}

/* Custom scrollbar */
.content-container::-webkit-scrollbar {
    width: 8px;
}

.content-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.content-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.content-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Table styles */
::v-deep .v-data-table th {
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
}

::v-deep .v-data-table td {
    font-size: 0.875rem !important;
}
</style>