<script>
import PacklistService from '@/services/PacklistService';
import ProductionService from '@/services/ProductionService';

export default {
    name: 'RetoureSettingsModal',
    props: {
        show: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            printers: [],
            currentPrinter: null,
            tab: 0,
            settings: false
        }
    },
    methods: {
        async getPrinters() {
            let response = await PacklistService.getPrinters();
            
            if (response.status === 200) {
                this.printers = response.data;
            }
        },
        getCurrentPrinter() {
            let currentPrinter = this.$cookies.get('printer');

            if (currentPrinter) {
                this.currentPrinter = this.printers.find(p => p._id === currentPrinter._id);
            }
        },
        selectCurrentPrinter(printer) {
            this.$cookies.set('printer', printer);
            this.currentPrinter = printer;
        },
        close() {
            this.$emit('close')
        }
    },
    watch: {
        show: {
            async handler() {
                await this.getPrinters();
                this.getCurrentPrinter();
            }
        }
    }
}
</script>

<template>
    <v-dialog 
    v-model="show" 
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition">
        <v-card class="settings-dialog">
            <!-- Fixed Header -->
            <div class="fixed-header">
                <v-card-title>
                    <h3>Einstellungen</h3>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                
                <!-- Fixed Tabs -->
                <v-tabs v-model="tab">
                    <v-tabs-slider color="light-blue"></v-tabs-slider>
                    <v-tab>
                        <v-icon>mdi-printer</v-icon>
                        Drucker
                    </v-tab>
                    <v-tab>
                        <v-icon>mdi-magnify</v-icon>
                        Suche
                    </v-tab>
                </v-tabs>
            </div>
            
            <!-- Scrollable Content -->
            <div class="scrollable-content">
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div class="tab-content-inner">
                            <div 
                                v-for="(printer, index) in printers" 
                                :key="index" 
                                :class="[
                                    'printer-item', 
                                    currentPrinter && currentPrinter._id === printer._id ? 'printer-selected' : ''
                                ]" 
                                @click="selectCurrentPrinter(printer)"
                            >
                                <div class="printer-info">
                                    <span class="printer-name">{{ printer.name }}</span>
                                    <span class="printer-id">{{ printer._id }}</span>
                                </div>
                                <v-icon v-if="currentPrinter && currentPrinter._id === printer._id" color="white" small class="ml-2">
                                    mdi-check
                                </v-icon>
                            </div>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="tab-content-inner">
                            <span>Einstellungen</span>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
            
            <!-- Fixed Footer Actions -->
            <div class="fixed-footer">
                <v-btn text color="grey darken-1" @click="close">Abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" depressed>Speichern</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.settings-dialog {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.fixed-header {
    background-color: white;
    z-index: 10;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.scrollable-content {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
}

.tab-content-inner {
    padding-bottom: 80px; /* Provide space at bottom so content isn't hidden behind footer */
}

.fixed-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: white;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
}

.printer-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: #f5f5f5;
    cursor: pointer;
    transition: all 0.2s ease;
}

.printer-item:hover {
    background-color: #e0e0e0;
}

.printer-selected {
    background-color: #f44336;
    color: white;
}

.printer-info {
    display: flex;
    flex-direction: column;
}

.printer-name {
    font-weight: 500;
    font-size: 16px;
}

.printer-id {
    font-size: 12px;
    opacity: 0.7;
}
</style>
