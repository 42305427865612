<script>
export default {
    name: 'RetoureEditAddressModal',
    props: {
        show: {
            type: Boolean,
            required: true
        },
        currentEditingAddress: {
            type: Object,
            required: true
        },
        currentEditingRetoureItem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            address: {
                BillbeeId: this.currentEditingAddress.BillbeeId,
                FirstName: this.currentEditingAddress.FirstName,
                LastName: this.currentEditingAddress.LastName,
                Company: this.currentEditingAddress.Company,
                NameAddition: this.currentEditingAddress.NameAddition,
                Street: this.currentEditingAddress.Street,
                HouseNumber: this.currentEditingAddress.HouseNumber,
                Zip: this.currentEditingAddress.Zip,
                City: this.currentEditingAddress.City,
                CountryISO2: this.currentEditingAddress.CountryISO2,
                Country: this.currentEditingAddress.Country,
                Line2: this.currentEditingAddress.Line2,
                Email: this.currentEditingAddress.Email,
                State: this.currentEditingAddress.State,
                Phone: this.currentEditingAddress.Phone
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit('update:show', false);
        },
        saveAddress() {
            let updatedObj = {
                retoure: this.currentEditingRetoureItem,
                billbee: this.address
            }

            this.$emit('save-address', updatedObj);
        }
    },
}
</script>

<template>
    <v-dialog v-model="show" max-width="600px" persistent>
        <v-card>
            <v-card-title class="headline">
                Adresse bearbeiten
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="pt-4">
                <!-- Display-only information -->
                <v-card outlined class="mb-4 info-card">
                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Billbee ID</v-list-item-subtitle>
                                <v-list-item-title>{{ currentEditingAddress.BillbeeId }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Email</v-list-item-subtitle>
                                <v-list-item-title>{{ currentEditingAddress.Email }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Telefon</v-list-item-subtitle>
                                <v-list-item-title>{{ currentEditingAddress.Phone || 'Nicht angegeben' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>Land</v-list-item-subtitle>
                                <v-list-item-title>{{ currentEditingAddress.CountryISO2 }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>

                <!-- Editable form -->
                <v-form @submit.prevent="saveAddress" ref="form">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="address.FirstName"
                                label="Vorname"
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="address.LastName"
                                label="Nachname"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="address.Company"
                                label="Firma"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="8">
                            <v-text-field
                                v-model="address.Street"
                                label="Straße"
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-text-field
                                v-model="address.HouseNumber"
                                label="Hausnummer"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="address.Zip"
                                label="PLZ"
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                v-model="address.City"
                                label="Stadt"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="address.CountryISO2"
                                label="Land"
                                outlined
                                dense
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="address.Email"
                                label="Email"
                                outlined
                                dense
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    @click="saveAddress"
                >
                    Speichern
                </v-btn>
                <v-btn
                    text
                    @click="closeDialog"
                >
                    Abbrechen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.v-card-text {
    max-height: 70vh;
    overflow-y: auto;
}

.info-card {
    background-color: #f5f5f5;
}

::v-deep .v-list-item__subtitle {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.6);
}
</style> 