<template>
    <v-dialog v-model="show" max-width="1300px" persistent>
        <v-card class="modal-card">
            <v-card-title class="headline d-flex justify-space-between modal-header">
                Bestellung suchen
                <v-btn icon @click="$emit('update:show', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <div v-if="isSearching" class="loading-overlay">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="64"
                ></v-progress-circular>
                <div class="loading-text mt-4">Suche nach Bestellungen...</div>
            </div>
            
            <div v-show="!isSearching" class="table-container">
                <v-data-table
                    :headers="searchHeaders"
                    :items="searchResults"
                    :items-per-page="10"
                    class="elevation-1"
                    :item-class="'clickable-row'"
                    :loading="isSearching"
                    fixed-header
                    :height="tableHeight"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 50],
                        'items-per-page-text': 'Einträge pro Seite'
                    }"
                >
                    <template v-slot:item.customer="{ item }">
                        <div class="customer-info">
                            <div class="customer-name">{{ item.customer.firstName }} {{ item.customer.lastName }}</div>
                            <div class="customer-address grey--text text--darken-1">{{ item.customer.adress }}</div>
                        </div>
                    </template>

                    <template v-slot:item.createDate="{ item }">
                        {{ new Date(item.createDate).toLocaleDateString('de-DE') }}
                    </template>

                    <template v-slot:item.orderId="{ item }">
                        <v-chip
                            color="primary"
                            text-color="white"
                            small
                            class="font-weight-medium"
                        >
                            {{ item.orderId }}
                        </v-chip>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <div class="d-flex justify-end">
                            <v-btn
                                color="primary"
                                class="mr-2"
                                small
                                outlined
                                @click="viewOrder(item)"
                                title="Details ansehen"
                            >
                                <v-icon left small>mdi-eye</v-icon>
                                Details
                            </v-btn>
                            <!--<v-btn
                                color="success"
                                small
                                @click="$emit('submit-retoure', item)"
                                title="Retoure anmelden"
                            >
                                <v-icon left small>mdi-undo-variant</v-icon>
                                Retoure
                            </v-btn>-->
                        </div>
                    </template>

                    <template v-slot:no-data>
                        <div class="pa-6 text-center">
                            <v-icon large color="grey lighten-1">mdi-magnify-off</v-icon>
                            <div class="text-subtitle-1 mt-2 grey--text">Keine Suchergebnisse gefunden</div>
                        </div>
                    </template>
                </v-data-table>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'RetoureSearchModal',
    props: {
        show: Boolean,
        isSearching: Boolean,
        searchResults: Array
    },
    data() {
        return {
            tableHeight: '60vh',
            searchHeaders: [
                { 
                    text: 'Bestellnummer',
                    value: 'orderId',
                    align: 'left',
                    width: '150'
                },
                { 
                    text: 'Datum',
                    value: 'createDate',
                    align: 'left',
                    width: '120'
                },
                { 
                    text: 'Kunde & Adresse',
                    value: 'customer',
                    align: 'left'
                },
                { 
                    text: 'Aktionen',
                    value: 'actions',
                    align: 'right',
                    sortable: false,
                    width: '200'
                }
            ]
        }
    },
    methods: {
        viewOrder(item) {
            this.$emit('view-order', {
                item: item,
                isFromSearch: true
            });
        }
    }
}
</script>

<style scoped>
.modal-card {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
}

.modal-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.table-container {
    flex: 1;
    overflow: hidden;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
}

.customer-info {
    padding: 4px 0;
}

.customer-name {
    font-weight: 500;
    margin-bottom: 2px;
}

.customer-address {
    font-size: 0.875rem;
}

.clickable-row {
    cursor: pointer;
}

.clickable-row:hover {
    background-color: #f5f5f5 !important;
}

.v-data-table ::v-deep th {
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    text-transform: none !important;
    background: white !important;
}

.v-data-table ::v-deep td {
    height: 60px !important;
}

.v-data-table ::v-deep .v-data-footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.v-data-table ::v-deep .v-data-table__wrapper {
    overflow-y: auto;
}

.v-data-table ::v-deep .v-data-table__wrapper::-webkit-scrollbar {
    width: 8px;
}

.v-data-table ::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.v-data-table ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.v-data-table ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style> 