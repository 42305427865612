<template>
    <div>
        <Header />

        <div class="container">
            <div class="search-container">
                <v-text-field
                    placeholder="Suche..."
                    filled
                    rounded
                    dense
                    v-model="search"
                    @keydown.enter="searchRetoureItems"
                    clearable
                >
                    <template v-slot:append>
                        <v-btn
                            icon
                            x-small
                            class="search-settings-btn"
                            @click="dialogs.settings = true"
                            elevation="0"
                        >
                            <v-icon small>mdi-cog</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </div>
            <div>
                <template>
                    <div class="d-flex justify-space-between">
                        <v-tabs v-model="tab">
                            <v-tabs-slider color="light-blue"></v-tabs-slider>
                            <v-tab>
                                Offene Fehlzustellungen
                                <span class="badge badge-danger">{{ openRetoureItems.length }}</span>
                            </v-tab>
                            <v-tab>
                                Fehlzustellungsregal
                                <span class="badge badge-danger">{{ toSendRetoureItems.length + trashRetoureItems.length }}</span>
                            </v-tab>
                        </v-tabs>
                    </div>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-data-table 
                            :headers="headers" 
                            :items="openRetoureItems"
                            :items-per-page="20"
                            class="elevation-1"
                            item-key="id"
                            @click:row="viewOrderDetails"
                            :item-class="row => row.id === highlightedRowId ? 'highlighted-row' : ''"
                            :custom-props="{ 'data-id': item => item.id }"
                            :no-data-text="'Keine Einträge gefunden'"
                            >
                                <template v-slot:no-data>
                                    <div class="empty-state">
                                        <v-icon size="64" color="grey lighten-1">mdi-file-search-outline</v-icon>
                                        <h3 class="mt-4">Keine Einträge gefunden</h3>
                                        <p class="text-center">
                                            Es wurden keine passenden Einträge gefunden. 
                                            <br>
                                            <span class="search-hint">
                                                <v-icon small class="mr-1">mdi-keyboard-return</v-icon>
                                                Drücke Enter um nach weiteren Bestellungen zu suchen.
                                            </span>
                                        </p>
                                        <v-btn 
                                            color="primary" 
                                            outlined 
                                            class="mt-4" 
                                            @click="clearSearch"
                                            v-if="search && search.trim() !== ''"
                                        >
                                            <v-icon left>mdi-close</v-icon>
                                            Suche zurücksetzen
                                        </v-btn>
                                    </div>
                                </template>
                                <template v-slot:item.orderInfo="{ item }">
                                    <div class="stacked-info">
                                        <div class="order-number">
                                            <v-chip color="blue darken-4 white--text">
                                                {{ item.orderId }}
                                            </v-chip>
                                        </div>
                                        <div class="sku-number">
                                            <v-chip color="black" text-color="white">
                                                {{ item.sku }}
                                            </v-chip>
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:item.carrier="{ item }">
                                    <v-chip
                                        :color="item.carrier && item.carrier.toLowerCase() === 'dhl' ? 'yellow' : (item.carrier && item.carrier.toLowerCase() === 'dpd' ? 'red' : 'grey')"
                                        :text-color="item.carrier && item.carrier.toLowerCase() === 'dhl' ? 'red' : (item.carrier && item.carrier.toLowerCase() === 'dpd' ? 'white' : 'black')"
                                        small
                                    >
                                        {{ item.carrier || 'N/A' }}
                                    </v-chip>
                                </template>
                                <template v-slot:item.details="{ item }">
                                    <div class="details-info">
                                        <div class="type">{{ item.type }}</div>
                                        <div class="reason multi-line">{{ item.reason }}</div>
                                    </div>
                                </template>
                                <template v-slot:item.date="{ item }">
                                    {{ new Date(item.date).toLocaleDateString('de-DE') }}
                                </template>
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item>
                            <RetoureShelfManager 
                                ref="shelfManager"
                                :toSendRetoureItems="toSendRetoureItems"
                                :trashRetoureItems="trashRetoureItems"
                                @putRetoureItemInTrash="putRetoureIntoTrash"
                                @refreshRetoureShelf="refreshRetoureShelf"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </template>
            </div>
        </div>

        <!-- Update RetoureModals to handle all modals -->
        <RetoureModals
            :dialogs.sync="dialogs"
            :order-data="orderData"
            :selected-order-id="selectedOrderId"
            :selected-retoure-item="selectedRetoureItem"
            :is-loading-order-data="isLoadingOrderData"
            :search-results="searchResults"
            :is-searching="isSearching"
            @view-order-details="viewOrderDetails"
            @submit-retoure="submitRetoure"
            @new-retoure="handleNewRetoure"
            @confirm-address="confirmCustomersAddress"
            @put-into-trash="putRetoureIntoTrash"
            @reload-order-data="gerOrderData"
            @save-address="handleSaveCustomersAddress"
        />
    </div>
</template>

<script>
import Header from "../header";
import ProductionService from "@/services/ProductionService";
import RetoureShelfManager from "./RetoureShelfManager.vue";
import RetoureModals from "./RetoureModals.vue";

export default {
    name: 'RetoureV2',
    components: {
        Header,
        RetoureShelfManager,
        RetoureModals
    },
    data() {
        return {
            retoureItems: [],
            headers: [
                { text: 'Fach', value: 'shelf' },
                { text: 'Bestellung', value: 'orderInfo' },
                { text: 'Carrier', value: 'carrier' },
                { text: 'Details', value: 'details' },
                { text: 'Datum', value: 'date', sortable: true },
                { value: 'actions' }
            ],
            tab: 0,
            dialogs: {
            orderDialog: false,
                searchDialog: false,
                retoureDialog: false,
                editAdressDialog: false,
                infoDialog: false,
                warranty: false,
                settings: false,
                printerWarning: false
            },
            selectedOrderId: null,
            selectedRetoureItem: null,
            highlightedRowId: null,
            search: '',
            searchResults: [],
            isSearching: false,
            orderData: null,
            isLoadingOrderData: false
        }
    },
    methods: {
        async getRetoureItems() {
            const response = await ProductionService.getRetoureHistory();
            this.retoureItems = response.data;
        },
        async gerOrderData(orderId) {
            try {
                this.isLoadingOrderData = true;
                const response = await ProductionService.getOrder(orderId);
                this.orderData = response.data;
            } catch (error) {
                console.error('Failed to load order data:', error);
                this.orderData = null;
            } finally {
                this.isLoadingOrderData = false;
            }
        },
        async viewOrderDetails(item) {
            this.selectedOrderId = item.orderId;
            this.selectedRetoureItem = item;

            console.log(item);
            
            try {
                this.isLoadingOrderData = true;
                let orderId;
                let response;

                if (item.shop === 'acs') {
                    orderId = item.managedId;
                    response = await ProductionService.getOrderByInternalId(orderId);
                } else {
                    orderId = item.orderId;
                    response = await ProductionService.getOrder(orderId);
                }
                
                if (response && response.data) {
                    this.orderData = response.data.Data || response.data;
                    this.dialogs.orderDialog = true;
                } else {
                    this.orderData = null;
                    this.dialogs.orderNotFound = true;
                }
            } catch (error) {
                console.error('Failed to load order details:', error);
                this.orderData = null;
            } finally {
                this.isLoadingOrderData = false;
            }
        },
        async submitRetoure(retoureData) {
            this.dialogs.searchDialog = false;
            this.dialogs.retoureDialog = true;
            this.orderId = retoureData.orderId;
            this.sku = retoureData.sku;
            
            this.customer = {
                firstName: retoureData.customer.firstName,
                lastName: retoureData.customer.lastName,
                adress: retoureData.customer.adress
            };
        },
        async handleNewRetoure(retoureData) {
            let printer = this.$cookies.get('printer');

            if (!printer) {
                this.dialogs.printerWarning = true;
                return;
            }

            retoureData.printer = printer.url;
            let response = await ProductionService.createRetoure(retoureData);

            if (response.status === 200) {
                this.retoureId = response.data.retoureId;
                this.retoureShelf = response.data.shelf;
                await this.getRetoureItems();
                
                // Close the shelf dialog if it's open
                if (this.$refs.shelfManager) {
                    this.$refs.shelfManager.closeDialog();
                }
            }
        },
        async putRetoureIntoTrash(retoureItem) {
            try {
                retoureItem.status = 'trash';
            
                let orderObj = {
                    retoure: retoureItem,
                    billbee: null
                }
                let response = await ProductionService.updateRetoure(orderObj, false);

                if (response.status === 200) {
                    this.dialogs.orderDialog = false;
                    await this.getRetoureItems();
                }
            } catch (error) {
                console.error('Failed to put retoure into trash:', error);
            } finally {
                this.dialogs.orderDialog = false;
            }
        },
        async restoreRetoureToOpen(retoureItem) {
            try {
                retoureItem.status = 'open';
                
                let orderObj = {
                    retoure: retoureItem,
                    billbee: null
                }
                let response = await ProductionService.updateRetoure(orderObj, false);

                if (response.status === 200) {
                    this.dialogs.orderDialog = false;
                    await this.getRetoureItems();
                }
            } catch (error) {
                console.error('Failed to restore retoure to open:', error);
            }
        },
        async confirmCustomersAddress(orderData) {
            try {
                orderData.status = 'toBeReshipped';
                
                let orderObj = {
                    retoure: orderData,
                    billbee: null
                }

                let response = await ProductionService.updateRetoure(orderObj, false);

                if (response.status === 200) {
                    await this.getRetoureItems();
                }
            } catch (error) {
                console.error('Failed to confirm customers address:', error);
            } finally {
                this.dialogs.orderDialog = false;
            }
        },
        async handleSaveCustomersAddress(data) {
            try {
                data.retoure.status = 'toBeReshipped';
                let response = await ProductionService.updateRetoure(data, true);

                if (response.status === 200) {
                    await this.getRetoureItems();
                }
            } catch (error) {
                console.error('Failed to save customers address:', error);
            } finally {
                this.dialogs.orderDialog = false;
                this.dialogs.editAdressDialog = false;
            }
        },
        async searchRetoureItems() {
            if (this.search && this.search.trim() !== '') {
                this.dialogs.searchDialog = true;
                this.isSearching = true;
                
                try {
                    let searched = await ProductionService.searchOrder(this.search);
                    this.searchResults = searched.data || [];
                } catch (error) {
                    console.error('Failed to search orders:', error);
                    this.searchResults = [];
                } finally {
                    this.isSearching = false;
                }
            }
        },
        handleKeydown(event) {
            if (event.key === 'Enter') {
                this.searchRetoureItems();
            }
        },
        clearSearch() {
            this.search = '';
            this.searchResults = [];
            // Close the search dialog if it's open
            if (this.dialogs.searchDialog) {
                this.dialogs.searchDialog = false;
            }
        },
        refreshRetoureShelf() {
            this.getRetoureItems();
        }
    },
    computed: {
        openRetoureItems() {
            // If there's no search query, return all open items
            if (!this.search || this.search.trim() === '') {
                return this.retoureItems.filter(item => item.status === 'open');
            }
            
            // If there is a search query, filter open items by the search term
            const searchTerm = this.search.toLowerCase().trim();
            return this.retoureItems.filter(item => {
                // Only include items with 'open' status
                if (item.status !== 'open') return false;
                
                // Check various fields for matches
                return (
                    // Check order ID
                    (item.orderId && item.orderId.toLowerCase().includes(searchTerm)) ||
                    // Check SKU
                    (item.sku && item.sku.toLowerCase().includes(searchTerm)) ||
                    // Check carrier
                    (item.carrier && item.carrier.toLowerCase().includes(searchTerm)) ||
                    // Check reason
                    (item.reason && item.reason.toLowerCase().includes(searchTerm)) ||
                    // Check type
                    (item.type && item.type.toLowerCase().includes(searchTerm)) ||
                    // Check customer name if available
                    (item.customer && item.customer.firstName && 
                     item.customer.firstName.toLowerCase().includes(searchTerm)) ||
                    (item.customer && item.customer.lastName && 
                     item.customer.lastName.toLowerCase().includes(searchTerm))
                );
            });
        },
        trashRetoureItems() {
            return this.retoureItems.filter(i => i.trash === 1 || i.status === 'trash');
        },
        toSendRetoureItems() {
            return this.retoureItems.filter(i => i.status === 'toBeReshipped');
        }
    },
    async mounted() {
        await this.getRetoureItems();
        window.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeydown);
    }
}
</script>

<style scoped>
.badge {
    margin-left: 10px;
    padding: 4px 8px;
    border-radius: 5000px;
}

.badge-danger {
    background-color: #f44336;
    color: #fff;
}

.search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 50%;
    margin: 0 auto !important;
}

.clickable-row {
    cursor: pointer;
}

.highlighted-row {
    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.empty-state {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.empty-state h3 {
    font-size: 1.5rem;
    margin-bottom: 8px;
}

.empty-state p {
    max-width: 400px;
    margin: 0 auto;
}

.stacked-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px 0;
}

.details-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px 0;
}

.details-info .type {
    font-weight: 500;
    font-size: 0.9em;
}

.details-info .reason.multi-line {
    font-size: 0.85em;
    color: rgba(0, 0, 0, 0.6);
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2;
    max-height: 3.6em; /* 1.2em * 3 lines */
}

/* Ensure table rows have enough height for content */
::v-deep .v-data-table tbody tr {
    height: auto !important;
    min-height: 72px;
}

/* Adjust cell padding for better spacing */
::v-deep .v-data-table td {
    padding: 8px 16px !important;
}

/* Fix button alignment and appearance */
::v-deep .search-settings-btn {
    margin: 0;
    padding: 0;
    height: 24px;
    width: 24px;
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Ensure icon is centered */
::v-deep .search-settings-btn .v-icon {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
}

/* Fix alignment with other append items */
::v-deep .v-input__append-inner {
    margin-top: 0 !important;
    align-self: center;
}
</style>